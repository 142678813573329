export const en = {
    "home": "Home",
    "products": "Products",
    "categories": "Categories",
    "about-us": "About Us",
    "quick_links": "Quick Links",
    "previous": "Previous",
    "Brand": "Brand",
    "Description": "Description",
    "Dynamic Variant": "Dynamic Variant",
    "Similar Products": "Similar Products",
    "next": "Next",
    "results": "Results",
    "privacy": "Privacy",
    "Shop By Category": "Shop By Category",
    "View All": "View All",
    "all": "All",
    "subcategory": "Subcategory",
    "Explore All": "Explore All",
    'Featured Products': 'Featured Products', 
    "Working Hours": "Working Hours",
    "Working Days": "Working Days",
    "Best seller": "Best seller", 
    "Show Rooms": "Show Rooms",
    'Offers': 'Offers', 
    'branches': 'Branches', 
    'brands': 'Brands', 
    "Contacts": "Contacts",
    "Follow us": "Follow us",
    'New Arrivals': 'New Arrivals',
    "There's no Products": "There's no Products",
    "Explore": "Explore",
    "Feature Products": "Feature Products",
    "QUALITY": "QUALITY BROUGHT TO YOU BY MASTER HN",
    "Best Selling Products": "Best Selling Products",
    "DOWNLOAD MASTER HN": "DOWNLOAD MASTER HN",
    "Style Up Your Looks": "Style Up Your Looks",
    "All Rights": "All Rights reserved",
    "Hasan Al Naser": "Hasan Al Naser",
    "Powered By": "Powered By",
    "Total Price": "Total Price",
    "Continue": "Continue",
    "more Details": "More Details",
    "Your Cart is Empty": "Your Cart is Empty",
    "KWD": "KWD",
    "filter by": "Filter by",
    "Cash in Delivery": "Cash in Delivery",
    "ordered successfully description": "Your order has been submitted successfully",
    "ordered failed": "Something went wrong!",
    "ordered failed description": "The payment not proceed",
    "Cancel": "Cancel",
    "Cancel Message": "Are you sure you want to cancel this order? This action cannot be undone, and any progress related to the order will be lost.",
    "try again": "Try Again",
    "out of stock": "Out of Stock",
    "Download Pdf": "Download Pdf",
    "Style Up": "Style Up",
    "Add To Cart": "Add To Cart",
    "contact": "Contact",
    "Name": "Name",
    "Email": "Email",
    "Phone": "Phone",
    "Message": "Message",
    "CONTACT US ON WHATSAPP": "CONTACT US ON WHATSAPP",
    "GET IN TOUCH": "GET IN TOUCH",
    "Product": "Product",
    "Price": "Price",
    "Quantity": "Quantity",
    "Total": "Total",
    "Sub Total": "Sub Total",
    "Payment Details": "Payment Details",
    "Proceed": "Do You Want To Proceed To Checkout As",
    "Login": "Login",
    "Continue as a Guest": "Continue as a Guest",
    "Login Into Your Account": "Login Into Your Account",
    "Create New Account": "Create New Account",
    "Password": "Password",
    "Don't Have Account": "Don't Have Account",
    "Sign Up": "Sign Up",
    "Have Account": "Have Account",
    "Checkout": "Checkout",
    "User Details": "User Details",
    "Full Name": "Full Name",
    "Address Details": "Address Details",
    "Area": "Area",
    "Block": "Block",
    "Street": "Street",
    "Avenue": "Avenue",
    "term and conditions": "Term and Conditions",
    "Floor Number": "Floor Number",
    "Optional": "Optional",
    "Other Instructions": "Other Instructions",
    "Address Type": "Address Type",
    "Building Number": "Building Number",
    "Floor": "Floor",
    "Apartment Number": "Apartment Number",
    "House Number": "House Number",
    "House": "House",
    "Flat": "Flat",
    "Office": "Office",
    "Office Number": "Office Number",
    "Extra Directions": "Extra Directions",
    "Please Enter": "Please Enter",
    "Please Select": "Please Select",
    "Coupon Code": "Coupon Code",
    "Apply": "Apply",
    "Payment Method": "Payment Method",
    "Payment": "Payment",
    "KNET": "KNET",
    "Visa/MasterCard": "Visa/MasterCard",
    "Payment Detail": "Payment Detail",
    "Discount": "Discount",
    "Delivery Charges": "Delivery Charges",
    "Submit Order": "Submit Order",
    "show some love to your bag": "Show Some Love to your Bag",
    "Fill your bag with things that make you happy": "Fill your bag with things that make you happy",
    "Profile": "Profile",
    "Logout": "Logout",
    "My Account": "My Account",
    "My Orders": "My Orders",
    "Update Account": "Update Account",
    "Update": "Update",
    "Change Password": "Change Password",
    "New Password": "New Password",
    "Old Password": "Old Password",
    "Confirm New Password": "Confirm New Password",
    "This Field is required": "This Field is required",
    "Amount": "Amount",
    "Cancel": "Cancel",
    "Add": "Add",
    "Add Address First": "Add Address First",
    "Buy it again": "Buy it again",
    "There are No Products": "There are No Products",
    "Product Added To Cart": "Product Added To Cart",
    "Forget Password?": "Forget Password?",
    "Default": "Default",
    "Forget Password": "Forget Password",
    "Back": "Back",
    "Delete": "Delete",
    "Address Added Successfully.": "Address Added Successfully.",
    "Address Updated Successfully.": "Address Updated Successfully.",
    "Address Deleted Successfully.": "Address Deleted Successfully.",
    "Message Sent Successfully.": "Message Sent Successfully.",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Message Required": "Message Required",
    "promo code is invalid!": "Promo Code is Invalid!",
    "Successfully Applied": "Successfully Applied",
    "Remove from Cart": "Remove from Cart",
    "Remove": "Remove",
    "Search": "Search",
    "Classic kitchen": "Classic Kitchen",
    "Modern kitchen": "Modern Kitchen",
    "No Address": "No Address",
    "Add New Address": "Add New Address",
    "Address Name": "Address Name",
    "Set Default": "Set Default",
    "Edit": "Edit",
}