import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import MHBannerService from '../../../services/MHBannerService';
import Loader from '../../../common/Loader';
import facebook from '../../../assets/facebook.svg'   
import MHSocialMediaService from '../../../services/MHSocialMediaService';
import './style.css'

function Header() {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const bannerService = new MHBannerService()
    const [social, setSocial] = useState({
      facebook: '',
      instagram: '',
      twitter: '',
      youtube: '',
      snapchat: '',
    })
    const mHSocialMediaService = new MHSocialMediaService()

    useEffect(()=>{
      setLoader(true)
      bannerService?.getList().then(res=>{
        if(res?.status === 200){
          let data = res?.data?.data?.map(img=> {
            return {
              image: img?.image
            }
          })
          setData(data)
        }
        setLoader(false)
      }).catch(()=> setLoader(false))

      mHSocialMediaService.getList().then(res=>{
        if(res?.status === 200 && !!res?.data){
            setSocial(res?.data?.data)
        }
      })
    },[])

  return (
    <div className='header-home'>
      {loader ? <div className='d-flex justify-content-center py-5'>
        <Loader />
      </div> : <div>
      <div className="social-media">
        {!!social?.facebook && <a rel="noreferrer" href={social?.facebook} target='_blank'>
          <img src={facebook} alt='facebook' />
        </a>}
        {!social?.whatsapp && <a rel="noreferrer" href={social?.whatsapp} target='_blank'>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#151515"/>
            <path d="M21.2188 9C15.2531 9 10.4375 13.8156 10.4375 19.7812C10.4375 22.225 11.2281 24.525 12.7375 26.3938L11.2281 30.9937C11.1562 31.2812 11.2281 31.5688 11.4438 31.7844C11.5156 31.9281 11.7313 32 11.875 32C11.9469 32 12.0906 32 12.1625 31.9281L17.1219 29.7C18.4156 30.275 19.7812 30.5625 21.2188 30.5625C27.1844 30.5625 32 25.7469 32 19.7812C32 13.8156 27.1844 9 21.2188 9ZM27.4719 23.7344C27.1844 24.5969 26.1062 25.3156 25.1719 25.4594C24.9563 25.5312 24.7406 25.5312 24.4531 25.5312C23.8781 25.5312 23.0156 25.3875 21.5062 24.7406C19.7812 24.0219 18.0562 22.5125 16.6906 20.5719V20.5C16.2594 19.8531 15.4688 18.6313 15.4688 17.3375C15.4688 15.7563 16.2594 14.9656 16.5469 14.6063C16.9062 14.2469 17.4094 14.0312 17.9844 14.0312C18.1281 14.0312 18.2 14.0312 18.3438 14.0312C18.8469 14.0312 19.2063 14.175 19.5656 14.8937L19.8531 15.4688C20.0688 16.0437 20.3562 16.6906 20.4281 16.7625C20.6438 17.1938 20.6438 17.5531 20.4281 17.9125C20.3562 18.1281 20.2125 18.2719 20.0687 18.4156C19.9969 18.5594 19.925 18.6312 19.8531 18.6312C19.7812 18.7031 19.7812 18.7031 19.7094 18.775C19.925 19.1344 20.3562 19.7812 20.9313 20.2844C21.7938 21.075 22.4406 21.2906 22.8 21.4344C22.9438 21.2906 23.0875 21.0031 23.3031 20.7875L23.375 20.6438C23.7344 20.1406 24.3094 19.9969 24.8844 20.2125C25.1719 20.3562 26.7531 21.075 26.7531 21.075L26.8969 21.1469C27.1125 21.2906 27.4 21.3625 27.5437 21.65C27.8312 22.2969 27.6156 23.2312 27.4719 23.7344Z" fill="white"/>
          </svg>
        </a>}
        
        {!!social?.instagram && <a rel="noreferrer" href={social?.instagram} target='_blank'>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_200_10349)">
        <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#151515"/>
        <g clip-path="url(#clip1_200_10349)">
        <path d="M20 10C22.717 10 23.056 10.01 24.122 10.06C25.187 10.11 25.912 10.277 26.55 10.525C27.21 10.779 27.766 11.123 28.322 11.678C28.8305 12.1779 29.224 12.7826 29.475 13.45C29.722 14.087 29.89 14.813 29.94 15.878C29.987 16.944 30 17.283 30 20C30 22.717 29.99 23.056 29.94 24.122C29.89 25.187 29.722 25.912 29.475 26.55C29.2247 27.2178 28.8311 27.8226 28.322 28.322C27.822 28.8303 27.2173 29.2238 26.55 29.475C25.913 29.722 25.187 29.89 24.122 29.94C23.056 29.987 22.717 30 20 30C17.283 30 16.944 29.99 15.878 29.94C14.813 29.89 14.088 29.722 13.45 29.475C12.7823 29.2245 12.1775 28.8309 11.678 28.322C11.1694 27.8222 10.7759 27.2175 10.525 26.55C10.277 25.913 10.11 25.187 10.06 24.122C10.013 23.056 10 22.717 10 20C10 17.283 10.01 16.944 10.06 15.878C10.11 14.812 10.277 14.088 10.525 13.45C10.7752 12.7822 11.1688 12.1773 11.678 11.678C12.1777 11.1692 12.7824 10.7757 13.45 10.525C14.088 10.277 14.812 10.11 15.878 10.06C16.944 10.013 17.283 10 20 10ZM20 15C18.6739 15 17.4021 15.5268 16.4645 16.4645C15.5268 17.4021 15 18.6739 15 20C15 21.3261 15.5268 22.5979 16.4645 23.5355C17.4021 24.4732 18.6739 25 20 25C21.3261 25 22.5979 24.4732 23.5355 23.5355C24.4732 22.5979 25 21.3261 25 20C25 18.6739 24.4732 17.4021 23.5355 16.4645C22.5979 15.5268 21.3261 15 20 15ZM26.5 14.75C26.5 14.4185 26.3683 14.1005 26.1339 13.8661C25.8995 13.6317 25.5815 13.5 25.25 13.5C24.9185 13.5 24.6005 13.6317 24.3661 13.8661C24.1317 14.1005 24 14.4185 24 14.75C24 15.0815 24.1317 15.3995 24.3661 15.6339C24.6005 15.8683 24.9185 16 25.25 16C25.5815 16 25.8995 15.8683 26.1339 15.6339C26.3683 15.3995 26.5 15.0815 26.5 14.75ZM20 17C20.7956 17 21.5587 17.3161 22.1213 17.8787C22.6839 18.4413 23 19.2044 23 20C23 20.7956 22.6839 21.5587 22.1213 22.1213C21.5587 22.6839 20.7956 23 20 23C19.2044 23 18.4413 22.6839 17.8787 22.1213C17.3161 21.5587 17 20.7956 17 20C17 19.2044 17.3161 18.4413 17.8787 17.8787C18.4413 17.3161 19.2044 17 20 17Z" fill="white"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_200_10349">
        <rect width="40" height="40" fill="white"/>
        </clipPath>
        <clipPath id="clip1_200_10349">
        <rect width="24" height="24" fill="white" transform="translate(8 8)"/>
        </clipPath>
        </defs>
        </svg>
        </a>}
        {!!social?.gmail && <a rel="noreferrer" href={`mailto: ${social?.gmail}`} target='_blank'>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_200_10356)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#151515"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 14.5353V25.8604C10 26.4766 10.451 26.9759 11.0723 26.9759H28.7471C29.3677 26.9759 29.8194 26.4708 29.8194 25.8604V14.5353C29.8194 13.8591 29.4155 13.334 28.7471 13.334H11.0723C10.3781 13.334 10 13.872 10 14.5353ZM11.6297 15.5217C11.6297 15.2488 11.7948 15.0927 12.0587 15.0927C12.2219 15.0927 18.6445 19.1624 19.0335 19.4011L20.0387 20.0262C20.3574 19.8127 20.6774 19.6301 21.0077 19.4088C21.6819 18.9779 27.6806 15.0927 27.8458 15.0927C28.1103 15.0927 28.2748 15.2488 28.2748 15.5217C28.2748 15.8108 27.7181 16.0979 27.3555 16.3191C25.0774 17.7075 22.8 19.2211 20.5445 20.6611C20.4129 20.7501 20.1581 20.9404 19.9671 20.9114C19.7542 20.8785 13.2187 16.6798 12.0297 15.9804C11.851 15.8753 11.6297 15.7791 11.6297 15.5217Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_200_10356">
        <rect width="40" height="40" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        </a>}
      </div>
      <Carousel data-bs-theme="dark" controls='false' touch={'true'}>
          {data?.map((item, index)=>{
              return <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={item?.image}
                alt="slide"
              />
              <div className='title'>
                <span>{item?.tag}</span>
                <p>{item?.title}</p>
              </div>
            </Carousel.Item>
          })}
      </Carousel>
      </div>}
    </div>
  );
}

export default Header;