import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../common/Loader';
import ShowRoomService from '../../../services/ShowRoomService';
import './style.css'
import { Card, CardBody } from 'reactstrap';

const Showrooms = () => {
  const [rooms, setRooms] = useState([])
  const [banner, setBanner] = useState('')
  const [loader, setLoader] = useState(false)
  const {t} = useTranslation()

  const showRoomService = new ShowRoomService()

  useEffect(()=>{
    setLoader(true)
    showRoomService?.getList({
      offset:0,
      limit:3
    }).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setRooms(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))

    showRoomService?.banner().then(res=>{
      if(res?.status === 200){
        if(!!res?.data?.data?.length){
          let info = res?.data?.data[0]?.image
          setBanner(info)
        }
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[])

  return (<div className='showrooms-section'>
    <h1 className='section-title'>{t("Show Rooms")}</h1>
  <div className='showrooms'>
    <img src={banner} alt='git' className='gif' />
    {loader ? <div className='d-flex justify-content-center py-5'>
        <Loader />
      </div> : <div className='row rooms'>
      {rooms?.map((room,index) => {
        return <div className='col-md-4 col-6' key={index}>
          <Card className='mx-3' style={{borderRadius: '30px'}}>
            <CardBody>
              <div className='cate position-relative'>
                <img src={room?.image} alt={room?.name} className='img w-100 h-100' />
                <div className='details'>
                  <p className='title'>{room?.name}</p>
                  <p className='address'>{room?.location}</p>
                  <p className='days'>{room?.day}</p>
                  <p className='times'>{room?.time}</p>
                  <p className='phone'>Tel: {room?.tel1}</p>
                  <p className='phone'>Tel: {room?.tel2}</p>
                  <a href={room?.location_url} target='_blank' className='location' rel="noreferrer">
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.488 22.5065V10.5025H1.5L22.5 1.5L13.488 22.5065Z" stroke="#F27405" stroke-width="1.8" stroke-linejoin="round"/>
                    </svg> */}

                    Location
                  </a>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      })}
    </div>}
  </div>
  </div>);
};

export default Showrooms;
