import React, { useEffect, useState } from 'react';
import './style.css'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProductCard from '../../Enums/ProductCard';
import ProductsService from '../../services/ProductsService';
import Pagination from '../../common/Pagination/Pagination';
import Loader from '../../common/Loader';
import NoData from '../../common/NoData';
import Select from "react-select"
import SubCategoriesService from '../../services/SubCategoriesService';

const Products = () => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [hasData, setHasData] = useState(null)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([])
  const [subCategory, setSubCategory] = useState('')
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})
  const subCategoriesService = new SubCategoriesService()
  // const [brandSearch, setBrandSearch] = useState('')
  // const [brandSelect, setBrandSelect] = useState('')
  // const [brands, setBrands] = useState([
  //   "ALFANAR", "LAPP Cables", "RAKCAB", "RRGLOBAL"
  // ])
  // const [modalSearch, setModalSearch] = useState('')
  // const [modalSelect, setModalSelect] = useState('')
  // const [modal, setModal] = useState([
  //   "ALFANAR", "LAPP Cables", "RAKCAB", "RRGLOBAL"
  // ])
  // const [sortBySelect, setSortBySelect] = useState('')
  // const [sortBy, setSortBy] = useState([
  //   "A-Z", "Z-A", "New in"
  // ])
  // const [sortByPriceSelect, setSortByPriceSelect] = useState('')
  // const [sortByPrice, setSortByPrice] = useState([
  //   "Low to High", "High to Low"
  // ])
  const {t} = useTranslation()
  const location = useLocation()
  const lang = useSelector(state => state?.lang?.lang)
  const productsService = new ProductsService()

  useEffect(()=>{
    let param = {}
    if(!!search) param['search'] = search
    if(location.pathname.includes('category')) param['category_id'] = location.pathname.split('/')[2]
    if(location.pathname.includes('brand')) param['brand_id'] = location.pathname.split('/')[2]
    if(!!subCategory?.value) param['sub_category_id'] = subCategory?.value
    setParams(param)
    setShouldUpdate(prev => !prev)
  },[location, search, subCategory])

  useEffect(()=>{
    if(location.pathname.includes('category')){
      subCategoriesService.getList(location.pathname.split('/')[2]).then(res=>{
        if(res?.status){
          let response = res?.data?.data?.data?.map(info => {
            return {
              ...info,
              label: lang === 'en' ? info?.name_en: info?.name_ar,
              value: info?.id
            }
          })
          setSubCategoriesOptions(response)
        }
      })
    }
  },[])

  return <div className='categories container'>
      {/* <Col md={3}>
        <div className='filter-section'>
          <h5>Filter</h5>
          <hr />

          <div className='brands'>
            <label className='brand-title'>Brand</label>
            <input 
              type='text' 
              name='brand'
              className='search'
              placeholder='Search'
              value={brandSearch}
              onChange={e=> setBrandSearch(e.target.value)}
            />
            {brands?.map((brand, index)=>{
              return <label key={index} className='brand-labels'>
                <input 
                  type='checkbox'
                  name='brand'
                />
                {brand}
              </label>
            })}
          </div>

          <hr />

          <div className='brands'>
            <label className='brand-title'>Model</label>
            <input 
              type='text' 
              name='model'
              className='search'
              placeholder='Search'
              value={modalSearch}
              onChange={e=> setModalSearch(e.target.value)}
            />
            {modal?.map((model, index)=>{
              return <label key={index} className='brand-labels'>
                <input 
                  type='checkbox'
                  name='model'
                />
                {model}
              </label>
            })}
          </div>
      
          <hr />

          <div className='brands'>
            <label className='brand-title'>Sort by</label>
            {sortBy?.map((by, index)=>{
              return <label key={index} className='brand-labels'>
                <input 
                  type='checkbox'
                  name='sortby'
                />
                {by}
              </label>
            })}
          </div>
          <hr />

          <div className='brands'>
            <label className='brand-title'>Price</label>
            {sortByPrice?.map((byp, index)=>{
              return <label key={index} className='brand-labels'>
                <input 
                  type='checkbox'
                  name='sortbyPrice'
                />
                {byp}
              </label>
            })}
          </div>
        </div>
      </Col> */}
      {loading && <div style={{minHeight: '90vh' , display: 'flex'}}>
        <Loader />
      </div>}
      <div>
        <div className='d-flex align-items-center justify-content-between mt-4'>
          <h5 className='type-title m-0'>
            {location.pathname === '/products' ? t("products") : lang === 'en' ? data[0]?.category?.name_en : data[0]?.category?.name_ar} 
            <span className='mx-2'>({data?.length} {t("results")})</span>
          </h5>
          {(subCategoriesOptions?.length && location?.pathname.includes('category')) ? <div className='d-flex align-items-center' style={{width: '17rem',justifyContent: 'space-between', gap: '12px'}}>
             <label className='text-nowrap'>{t('filter by')}:</label>
             <Select
              options={[
                {label: t('all'), value: ''},
                ...subCategoriesOptions
              ]}
              name='subCategories'
              className='w-100'
              placeholder={t("subcategory")}
              value={subCategory}
              onChange={e=> setSubCategory(e)}
            />
          </div> : ''}
        </div>
        {(hasData === 1 && !loading) && <div className='products'>
          <div className='row'>
            {data?.map((product, index)=>{
              return <div className="col-md-4 col-6 mb-3" key={index}>
                <ProductCard product={product} hasAddToCart={true} />
              </div>
            })}
          </div>
        </div>}
        {(hasData === 0 && !loading) && <NoData text={t(`There's no Product`)} />}

        <Pagination
              setData={setData}
              service={productsService}
              shouldUpdate={shouldUpdate}
              setHasData={setHasData}
              setLoading={setLoading}
              search={search}
              param={params}
            />
      </div>
  </div>
};

export default Products;
