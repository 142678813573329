import React, { useEffect, useState } from 'react';
import './style.css'
import { useTranslation } from 'react-i18next';
import AboutService from '../../services/AboutService';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Static = () => {
  const {t} = useTranslation()
  const [data, setData] = useState([])
  const lang = useSelector(state => state?.lang?.lang)
  const aboutService = new AboutService()
  const location = useLocation()

  useEffect(()=>{
    aboutService?.getList({type: location?.pathname === '/privacy' ? "Privacy" : 'about'}).then(res=>{
      if(res?.status === 200){
        setData(res?.data?.data)
      }
    })
  }, [location?.pathname])

  return (<div className='aboutUs'>
        <h5 className='type-title'>
          <span>{location?.pathname === '/privacy' ? t("privacy") : t("about-us")}</span>
        </h5>
      <div>
        {data?.map((info,index)=>{
            return <div className="about-us" key={index}>
                <div className='w-50 description'>
                  <h2 className='mb-4'>{lang === 'en' ? info?.title_en : info?.title_ar}</h2>
                  <p dangerouslySetInnerHTML={{ __html: lang === 'en' ? info?.description_en : info?.description_ar }}></p>
              </div>
              <div className='w-50 image'>
                <img src={info?.image} className='w-100' alt='about' />
              </div>
            </div>
        })}
      </div>
    </div>
  );
};

export default Static;
